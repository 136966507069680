import React from 'react'

import { Shape, Props } from './Shape'

export const Line: React.FC<Props> = props => {
  const { x1, x2, y1, y2 } = props.item.boundingBox

  return (
    <Shape {...props} showNonIntersectingResizeHandles={false}>
      <line x1={x1} y1={y1} x2={x2} y2={y2} />
    </Shape>
  )
}
