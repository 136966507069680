import React from 'react'

import { Shape, Props } from './Shape'

export const Ellipse: React.FC<Props> = props => {
  const { x1, x2, y1, y2 } = props.item.boundingBox

  let rx: number
  let ry: number
  let cx: number
  let cy: number

  if (x1 <= x2) {
    rx = (x2 - x1) / 2
    cx = x1 + rx
  } else {
    rx = (x1 - x2) / 2
    cx = x2 + rx
  }

  if (y1 <= y2) {
    ry = (y2 - y1) / 2
    cy = y1 + ry
  } else {
    ry = (y1 - y2) / 2
    cy = y2 + ry
  }

  return (
    <Shape {...props}>
      <ellipse cx={cx} cy={cy} rx={rx} ry={ry} />
    </Shape>
  )
}
