import { Maybe } from 'monet'
import React from 'react'

export enum ItemType {
  RECT,
  ELLIPSE,
  LINE,
  SELECT_AREA,
}

export interface BoundingBox {
  x1: number
  x2: number
  y1: number
  y2: number
}

export interface Item {
  id: Maybe<string>
  type: ItemType
  boundingBox: BoundingBox
}

export interface Tool {
  readonly id: string
  readonly label: string
  readonly icon: React.ReactNode
  readonly cursor: Maybe<string>
  readonly shortcut: Maybe<string>
  readonly itemType: ItemType
}

export interface Point {
  readonly x: number
  readonly y: number
}
