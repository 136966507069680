import React from 'react'

import { Shape, Props } from './Shape'

export const Rect: React.FC<Props> = props => {
  const { x1, x2, y1, y2 } = props.item.boundingBox

  const x = Math.min(x1, x2)
  const y = Math.min(y1, y2)
  const width = Math.abs(x2 - x1)
  const height = Math.abs(y2 - y1)

  return (
    <Shape {...props}>
      <rect x={x} y={y} width={width} height={height} />
    </Shape>
  )
}
