import React from 'react'
import { container } from 'tsyringe'

export const ContainerContext = React.createContext(container)

export const ContainerProvider: React.FC = ({ children }) => (
  <ContainerContext.Provider value={container}>
    {children}
  </ContainerContext.Provider>
)
