import { Maybe } from 'monet'
import { useEffect, useState } from 'react'
import { fromEvent, NEVER, Observable } from 'rxjs'
import { filter, tap } from 'rxjs/operators'

interface Modifiers {
  alt: boolean
  ctrl: boolean
  meta: boolean
  shift: boolean
}

const defaultModifiers: Partial<Modifiers> = {}

export const useKeyCapture = (shortcut: Maybe<string>, modifiers: Partial<Modifiers> = defaultModifiers) => {
  const [observable, setObservable] = useState<Observable<KeyboardEvent>>(NEVER)

  useEffect(() => {
    const obs = shortcut.cata(
      () => NEVER,
      value => {
        const filterKeyDown = (evt: KeyboardEvent) => {
          if (evt.key !== value) {
            return false
          }

          if (modifiers.alt && !evt.altKey) {
            return false
          }

          if (modifiers.ctrl && !evt.ctrlKey) {
            return false
          }

          if (modifiers.meta && !evt.metaKey) {
            return false
          }

          if (modifiers.shift && !evt.shiftKey) {
            return false
          }

          return true
        }

        return fromEvent<KeyboardEvent>(document, 'keydown').pipe(
          filter(filterKeyDown),
          tap(evt => evt.preventDefault()),
        )
      }
    )

    setObservable(obs)
  }, [shortcut, modifiers, setObservable])

  return observable
}
