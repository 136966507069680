import { Maybe } from 'monet'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useInstance } from '../hooks/useInstance'
import { useTools } from '../hooks/useTools'
import { ProjectService } from '../services/ProjectService'
import { Item } from '../types/domain'
import { Canvas } from './canvas/Canvas'
import { Menu } from './menu/Menu'

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #658b96;
`

const Credits = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: white;

  & > a {
    color: inherit;
    text-decoration: none;
  }
`

export const App: React.FC = () => {
  const projectService = useInstance(ProjectService)
  const tools = useTools()
  const [initialData, setInitialData] = useState<Item[]>([])
  const [activeTool, setActiveTool] = useState(Maybe.fromUndefined(tools[0]))

  useEffect(() => {
    projectService.load()
      .then(setInitialData)
  }, [])

  const handleChange = (items: Item[]) => {
    projectService.save(items)
  }

  return (
    <Container>
      <Canvas
        activeTool={activeTool}
        initialData={initialData}
        onChange={handleChange}
      />

      <Menu activeTool={activeTool} onToolChange={setActiveTool} />

      {process.env.INCLUDE_CREDITS && (
        <Credits>
          <a href="https://gitlab.com/gschambers/wireframe" target="_blank">
            <i className="fab fa-gitlab" /> gitlab.com/gschambers/wireframe
          </a>
        </Credits>
      )}
    </Container>
  )
}
