import axios from 'axios'
import { Maybe } from 'monet'
import { Item } from '../types/domain'
import { Serialized } from '../types/util/Serialized'
import { ProjectStore } from './ProjectStore'

export class ReadOnlySampleProjectStore implements ProjectStore {
  public async set(_items: Item[]) {
    // NO-OP 
  }

  public async get(): Promise<Item[]> {
    const res = await axios.get<Array<Serialized<Item>>>(`/samples/${process.env.SAMPLE_DATA_FILE}`)
    return res.data.map(item => this.deserialize(item))
  }

  public deserialize(item: Serialized<Item>): Item {
    return {
      ...item,
      id: Maybe.fromNull(item.id),
    }
  }
}
