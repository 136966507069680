import styled from 'styled-components'

export const Tooltip = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1.5rem;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  border-radius: 0.3rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
`
