import { Maybe } from 'monet'
import { Item } from '../types/domain'
import { Serialized } from '../types/util/Serialized'
import { ProjectStore } from './ProjectStore'

const KEY = 'wireframes'

export class LocalStorageProjectStore implements ProjectStore {
  public async set(items: Item[]) {
    const data = items.map(item => this.serialize(item))  
    window.localStorage.setItem(KEY, JSON.stringify(data))
  }

  public async get(): Promise<Item[]> {
    const data: Array<Serialized<Item>> = JSON.parse(window.localStorage.getItem(KEY) || '[]')
    return data.map(item => this.deserialize(item))
  }

  private deserialize(item: Serialized<Item>): Item {
    return {
      ...item,
      id: Maybe.fromNull(item.id),
    }
  }

  private serialize(item: Item): Serialized<Item> {
    return {
      ...item,
      id: item.id.orNull(),
    }
  }
}
