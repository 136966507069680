import 'reflect-metadata'

import React from 'react'
import ReactDOM from 'react-dom'
import { container } from 'tsyringe'

import { App } from './components/App'
import { ContainerProvider } from './context/ContainerContext'
import { ProjectService } from './services/ProjectService'
import { LocalStorageProjectStore } from './stores/LocalStorageProjectStore'
import { ProjectStore } from './stores/ProjectStore'
import { ReadOnlySampleProjectStore } from './stores/ReadOnlySampleProjectStore'

container.register<ProjectStore>('ProjectStore', {
  useClass: process.env.USE_SAMPLE_STORE
    ? ReadOnlySampleProjectStore
    : LocalStorageProjectStore,
})

container.registerSingleton(ProjectService)

ReactDOM.render(
  <ContainerProvider>
    <App />
  </ContainerProvider>,
  document.querySelector('#root')
)
