import { Maybe, Some } from 'monet'
import React from 'react'
import styled from 'styled-components'

import { useTools } from '../../hooks/useTools'
import { Tool } from '../../types/domain'
import { ToolSelector } from './ToolSelector'

interface Props {
  readonly activeTool: Maybe<Tool>
  readonly onToolChange: (tool: Maybe<Tool>) => void
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 5rem;
  background-color: #2b3f45;
  box-shadow: 0.2rem 0 0.8rem rgba(0, 0, 0, 0.25);
`

const Logo = styled.div`
  margin-top: 0.5rem;
  font-family: Dosis;
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
  color: #fff;

  &::before {
    content: "wf";
  }
`

const ToolList = styled.div`
  margin-top: 2rem;
`

export const Menu: React.FC<Props> = ({ activeTool, onToolChange }) => {
  const tools = useTools()

  return (
    <Container>
      <Logo />
      <ToolList>
        {tools.map(tool => (
          <ToolSelector
            key={tool.id}
            active={activeTool.equals(Some(tool))}
            tool={tool}
            onSelect={() => onToolChange(Some(tool))}
          />
        ))}
      </ToolList>
    </Container>
  )
}
