import { Maybe, None, Some } from 'monet'
import * as R from 'ramda'
import { Reducer } from 'react'
import * as uuid from 'uuid'

import { Item, ItemType, BoundingBox, Point } from '../../types/domain'

export enum ActionType {
  LOAD_ITEMS,

  DESELECT_ALL_ITEMS,
  DESELECT_ITEM,
  SELECT_ITEM,

  SELECT_START,
  SELECT_MOVE,
  SELECT_END,

  DELETE_SELECTED_ITEMS,

  DRAW_START,
  DRAW_MOVE,
  DRAW_END,

  MOVE_START,
  MOVE_MOVE,
  MOVE_END,
}

interface Action<T extends ActionType> {
  type: T
}

interface ActionWithPayload<T extends ActionType, P> {
  type: T
  payload: P
}

type Actions =
  | ActionWithPayload<ActionType.LOAD_ITEMS, Item[]>
  | Action<ActionType.DESELECT_ALL_ITEMS>
  | ActionWithPayload<ActionType.DESELECT_ITEM, Item>
  | ActionWithPayload<ActionType.SELECT_ITEM, Item>
  | Action<ActionType.DELETE_SELECTED_ITEMS>
  | ActionWithPayload<ActionType.SELECT_START, Point>
  | ActionWithPayload<ActionType.SELECT_MOVE, { point: Point, proportional: boolean }>
  | Action<ActionType.SELECT_END>
  | ActionWithPayload<ActionType.DRAW_START, { type: ItemType, point: Point, item: Maybe<Item> }>
  | ActionWithPayload<ActionType.DRAW_MOVE, { point: Point, proportional: boolean }>
  | Action<ActionType.DRAW_END>
  | ActionWithPayload<ActionType.MOVE_START, Point>
  | ActionWithPayload<ActionType.MOVE_MOVE, Point>
  | Action<ActionType.MOVE_END>

export enum ActivityType {
  DRAW,
  MOVE,
  SELECT,
}

interface Selection {
  [itemId: string]: boolean
}

interface State {
  gridSize: number

  items: {
    [id: string]: Item
  }

  activity: {
    type: Maybe<ActivityType>
    drawing: boolean
    item: Maybe<Item>
    anchor: Maybe<Point>
    selected: Selection
  }
}

const DEFAULT_GRID_SIZE = 10

export const initialState: State = {
  gridSize: DEFAULT_GRID_SIZE,

  items: {},

  activity: {
    type: None<ActivityType>(),
    drawing: false,
    item: None<Item>(),
    anchor: None<Point>(),
    selected: {},
  }
}

const snapToGrid = (n: number, gridSize: number) => {
  return Math.round(n / gridSize) * gridSize
}

const createItemWithAnchoringPoint = (type: ItemType, anchor: Point): Item => ({
  id: None<string>(),
  type,
  boundingBox: {
    x1: anchor.x,
    y1: anchor.y,
    x2: anchor.x,
    y2: anchor.y,
  },
})

const updateItemBoundingBox = (item: Item, anchor: Point, point: Point, proportional: boolean): Item => {
  const { x: anchorX, y: anchorY } = anchor 
  let { x, y } = point 

  if (proportional) {
    const size = Math.min(Math.abs(x - anchorX), Math.abs(y - anchorY))

    x = x > anchorX ? anchorX + size : anchorX - size
    y = y > anchorY ? anchorY + size : anchorY - size
  }

  const boundingBox = {
    x1: anchorX,
    y1: anchorY,
    x2: x,
    y2: y,
  }

  return {
    ...item,
    boundingBox,
  }
}

const normalizeBoundingBox = (boundingBox: BoundingBox): BoundingBox => {
  const { x1, x2, y1, y2 } = boundingBox

  return {
    x1: Math.min(x1, x2),
    x2: Math.max(x1, x2),
    y1: Math.min(y1, y2),
    y2: Math.max(y1, y2),
  }
}

const doItemsIntersect = (a: Item, b: Item): boolean => {
  const aBox = normalizeBoundingBox(a.boundingBox)
  const bBox = normalizeBoundingBox(b.boundingBox)

  // A is wholly left or right of B
  if (aBox.x1 > bBox.x2 || bBox.x1 > aBox.x2) {
    return false
  }

  // A is wholly above or below B
  if (aBox.y1 > bBox.y2 || bBox.y1 > aBox.y2) {
    return false
  }

  // B contains A without overlap
  if (aBox.x1 > bBox.x1 &&
      aBox.x2 < bBox.x2 &&
      aBox.y1 > bBox.y1 &&
      aBox.y2 < bBox.y2) {
    return false
  }

  return true
}

const isDrawing = (state: State) => state.activity.drawing

export const reducer: Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case ActionType.LOAD_ITEMS: {
      const items = R.reduce((acc, item) => {
        return item.id.cata(
          () => acc,
          itemId => ({ ...acc, [itemId]: item }),
        )
      }, {} as State['items'], action.payload)
      
      return R.assoc('items', items, state)
    }

    case ActionType.DESELECT_ALL_ITEMS: {
      return R.assocPath(['activity', 'selected'], {}, state)
    }

    case ActionType.DESELECT_ITEM: {
      const item = action.payload

      return item.id.cata(
        () => state,
        itemId => R.dissocPath(['activity', 'selected', itemId], state),
      )
    }

    case ActionType.SELECT_ITEM: {
      const item = action.payload

      return item.id.cata(
        () => state,
        itemId => R.assocPath(['activity', 'selected', itemId], true, state),
      )
    }

    case ActionType.DELETE_SELECTED_ITEMS: {
      const selectedItemIds = R.compose<State, Selection, string[]>(
        R.keys,
        s => s.activity.selected,
      )(state)

      const items = R.filter(item => item.id.map(val => !selectedItemIds.includes(val)).orSome(false), state.items)

      return {
        ...state,
        items,
      }
    }

    case ActionType.SELECT_START: {
      const anchor = action.payload

      const selectArea = createItemWithAnchoringPoint(
        ItemType.SELECT_AREA,
        anchor,
      )

      return {
        ...state,
        activity: {
          type: Some(ActivityType.SELECT),
          drawing: true,
          anchor: Some(anchor),
          item: Some(selectArea),
          selected: {},
        },
      }
    }

    case ActionType.SELECT_MOVE: {
      if (!isDrawing(state)) {
        return state
      }

      let selectArea = state.activity.item
      const anchor = state.activity.anchor
      const { point, proportional } = action.payload

      const update = (item: Item) => (anchor: Point) => updateItemBoundingBox(item, anchor, point, proportional)

      selectArea = anchor.ap(selectArea.map(update))

      const selected = selectArea.cata(
        () => ({} as Selection),
        selectArea => R.reduce<Item, Selection>((acc, item) => {
          return doItemsIntersect(selectArea, item)
            ? { ...acc, ...(item.id.map(itemId => ({ [itemId]: true })).orSome({})) }
            : acc
        }, {}, R.values(state.items))
      )

      return {
        ...state,
        activity: {
          ...state.activity,
          item: selectArea,
          selected,
        },
      }
    }

    case ActionType.SELECT_END: {
      return {
        ...state,
        activity: {
          ...state.activity,
          type: None<ActivityType>(),
          item: None<Item>(),
          anchor: None<Point>(),
          drawing: false,
        },
      }
    }

    case ActionType.DRAW_START: {
      const { type, point } = action.payload 
      let item = action.payload.item.orNull()
      let anchor = point

      if (item === null) {
        anchor = {
          x: snapToGrid(point.x, state.gridSize),
          y: snapToGrid(point.y, state.gridSize),
        }

        item = createItemWithAnchoringPoint(type, anchor)
      }

      return {
        ...state,
        activity: {
          ...state.activity,
          type: Some(ActivityType.DRAW),
          anchor: Some(anchor),
          item: Some(item),
          drawing: true,
        },
      }
    }

    case ActionType.DRAW_MOVE: {
      if (!isDrawing(state)) {
        return state
      }

      const { point, proportional } = action.payload
      const snappedPoint: Point = {
        x: snapToGrid(point.x, state.gridSize),
        y: snapToGrid(point.y, state.gridSize),
      }

      const { anchor } = state.activity
      let { item } = state.activity

      const update = (item: Item) => (anchor: Point) => updateItemBoundingBox(item, anchor, snappedPoint, proportional)

      item = anchor.ap(item.map(update))

      return R.assocPath(['activity', 'item'], item, state)
    }

    case ActionType.DRAW_END: {
      if (!isDrawing(state)) {
        return state
      }

      const item = state.activity.item
      const itemId = item.flatMap(val => val.id).orLazy(uuid.v4)

      state = item.cata(
        () => state,
        item => {
          const boundingBox = item.boundingBox

          if (boundingBox.x1 === boundingBox.x2 && boundingBox.y1 === boundingBox.y2) {
            return state
          }

          item = R.assoc('id', Some(itemId), item)
          return R.assocPath(['items', itemId], item, state)
        },
      )

      return {
        ...state,
        activity: {
          type: None<ActivityType>(),
          item: None<Item>(),
          anchor: None<Point>(),
          drawing: false,
          selected: { [itemId]: true },
        },
      }
    }

    case ActionType.MOVE_START: {
      const point = action.payload

      return {
        ...state,
        activity: {
          ...state.activity,
          type: Some(ActivityType.MOVE),
          drawing: true,
          anchor: Some({
            x: snapToGrid(point.x, state.gridSize),
            y: snapToGrid(point.y, state.gridSize),
          }),
        },
      }
    }

    case ActionType.MOVE_MOVE: {
      let point = action.payload

      point = {
        x: snapToGrid(point.x, state.gridSize),
        y: snapToGrid(point.y, state.gridSize),
      }

      const anchor = state.activity.anchor

      return anchor.cata(
        () => state,
        anchor => {
          const offsetX = point.x - anchor.x
          const offsetY = point.y - anchor.y

          const itemIds = R.keys(state.activity.selected) as string[]
          const items = R.reduce((items, itemId) => {
            const item = items[itemId]

            if (offsetX === 0 && offsetY === 0) {
              return items;
            }

            return {
              ...items,
              [itemId]: {
                ...item,
                boundingBox: {
                  x1: item.boundingBox.x1 + offsetX,
                  x2: item.boundingBox.x2 + offsetX,
                  y1: item.boundingBox.y1 + offsetY,
                  y2: item.boundingBox.y2 + offsetY,
                },
              }
            }
          }, state.items, itemIds)

          return {
            ...state,
            items,
            activity: {
              ...state.activity,
              anchor: Some(point),
            },
          }
        }
      )
    }

    case ActionType.MOVE_END: {
      return {
        ...state,
        activity: {
          ...state.activity,
          type: None<ActivityType>(),
          anchor: None<Point>(),
          drawing: false,
        },
      }
    }

    default:
      return state
  }
}
