import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { useKeyCapture } from '../../hooks/useKeyCapture'
import { Tool } from '../../types/domain'
import { Tooltip } from './Tooltip'

interface Props {
  readonly tool: Tool
  readonly active: boolean
  onSelect(): void
}

const Container = styled.div<{ active: boolean }>`
  position: relative;
  padding: 1rem 0;
  font-size: 1.8rem;
  text-align: center;
  color: #fff;
  border-style: solid;
  border-top-width: 0.1rem;
  border-bottom-width: 0.1rem;
  border-left-width: 0;
  border-right-width: 0;
  border-top-color: rgba(255, 255, 255, 0.1);
  border-bottom-color: rgba(0, 0, 0, 0.3);

  &:first-child {
    border-top-color: transparent;
  }

  &:last-child {
    border-bottom-color: transparent;
  }

  ${({ active }) => active && css`
    background-color: rgba(0, 0, 0, 0.2);
    border-top-color: rgba(0, 0, 0, 0.2);
    border-bottom-color: rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
  `}
`

export const ToolSelector: React.FC<Props> = ({ active, tool, onSelect }) => {
  const keys = useKeyCapture(tool.shortcut)
  const [tooltip, setTooltip] = useState(false)

  useEffect(() => {
    const subscription = keys.subscribe(() => onSelect())

    return () => {
      subscription.unsubscribe()
    }
  }, [keys, tool, onSelect])

  const shortcut = tool.shortcut
    .orNoneIf(!tooltip)
    .map(val => <Tooltip>{val}</Tooltip>)
    .orNull()

  return (
    <Container
      active={active}
      onMouseEnter={() => setTooltip(true)}
      onMouseLeave={() => setTooltip(false)}
      onClick={() => onSelect()}
    >
      {tool.icon} {shortcut}
    </Container>
  )
}
