import React from 'react'
import styled from 'styled-components'

import { Rect } from './Rect'
import { Props } from './Shape'

const SelectAreaRect = styled(Rect)`
  stroke: #fff;
  stroke-dasharray: 5, 5;
  stroke-width: 0.1rem;
`

export const SelectArea: React.FC<Props> = props => (
  <SelectAreaRect {...props} />
)
