import { Some } from 'monet'
import React from 'react'

import { ItemType, Tool } from '../types/domain'

const Icon: React.FC = ({ children }) => (
  <svg width={16} height={16}>
    <g fill="none" stroke="#fff" strokeWidth={2}>
      {children}
    </g>
  </svg>
)

const selectTool: Tool = {
  id: 'select',
  label: 'Select',
  icon: <i className="fas fa-mouse-pointer" style={{ fontSize: 20 }} />,
  shortcut: Some('v'),
  cursor: Some('default'),
  itemType: ItemType.SELECT_AREA,
}

const rectIcon = (
  <Icon>
    <rect x={1} y={1} width={14} height={14} />
  </Icon>
)

const rectTool: Tool = {
  id: 'rect',
  label: 'Rectangle',
  icon: rectIcon,
  shortcut: Some('m'),
  cursor: Some('crosshair'),
  itemType: ItemType.RECT,
}

const ellipseIcon = (
  <Icon>
    <ellipse rx={7} ry={7} cx={8} cy={8} />
  </Icon>
)

const ellipseTool: Tool = {
  id: 'ellipse',
  label: 'Ellipse',
  icon: ellipseIcon,
  shortcut: Some('e'),
  cursor: Some('crosshair'),
  itemType: ItemType.ELLIPSE,
}

const lineIcon = (
  <Icon>
    <line x1={2} y1={0} x2={14} y2={16} />
  </Icon>
)

const lineTool: Tool = {
  id: 'line',
  label: 'Line',
  icon: lineIcon,
  shortcut: Some('\\'),
  cursor: Some('crosshair'),
  itemType: ItemType.LINE,
}

export const useTools = () => {
  return [
    selectTool,
    rectTool,
    ellipseTool,
    lineTool,
  ]
}
