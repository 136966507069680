import { Maybe, None, Some } from 'monet'
import * as R from 'ramda'
import React, { useEffect, useReducer, useState } from 'react'
import { merge } from 'rxjs'
import styled from 'styled-components'

import { useKeyCapture } from '../../hooks/useKeyCapture'
import { Item, ItemType, Point, Tool } from '../../types/domain'
import { ActivityType, ActionType, reducer, initialState } from './reducer'
import { Ellipse } from './Ellipse'
import { Line } from './Line'
import { Rect } from './Rect'
import { SelectArea } from './SelectArea'
import { Props as ShapeProps } from './Shape'

const MOUSE_LEFT_BUTTON = 1;

interface Props {
  readonly activeTool: Maybe<Tool>
  readonly initialData: Item[]
  onChange(items: Item[]): void
}

const Container = styled.svg`
  width: 100%;
  height: 100%;
  z-index: 1;
`

const SmallGrid = styled.path`
  fill: none;
  stroke: #2b3f45;
  stroke-width: 0.025rem;
`

const Grid = styled.path`
  fill: none;
  stroke: #2b3f45;
  stroke-width: 0.05rem;
`

const grid = (gridSize: number): React.ReactNode => (
  <React.Fragment>
    <defs>
      <pattern id="smallGrid" width={gridSize} height={gridSize} patternUnits="userSpaceOnUse">
        <SmallGrid d={`M ${gridSize} 0 L 0 0 0 ${gridSize}`} />
      </pattern>

      <pattern id="grid" width={gridSize * 10} height={gridSize * 10} patternUnits="userSpaceOnUse">
        <rect width={gridSize * 10} height={gridSize * 10} fill="url(#smallGrid)" />
        <Grid d={`M ${gridSize * 10} 0 L 0 0 0 ${gridSize * 10}`} />
      </pattern>
    </defs>
    <rect width="100%" height="100%" fill="url(#grid)" />
  </React.Fragment>
)

const deleteShortcut = Some('Delete')
const backspaceShortcut = Some('Backspace')

export const Canvas: React.FC<Props> = ({ activeTool, initialData, onChange }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [enqueueSave, setEnqueueSave] = useState(false)
  const deletes = useKeyCapture(deleteShortcut)
  const backspaces = useKeyCapture(backspaceShortcut)
  const cursor = activeTool.flatMap(tool => tool.cursor).orSome('default')

  useEffect(() => {
    const subscription = merge(deletes, backspaces)
      .subscribe(() => {
        dispatch({ type: ActionType.DELETE_SELECTED_ITEMS })
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [dispatch, deletes, backspaces])

  useEffect(() => {
    dispatch({
      type: ActionType.LOAD_ITEMS,
      payload: initialData,
    })
  }, [initialData, dispatch])

  useEffect(() => {
    if (enqueueSave) {
      const items = R.values(state.items)
      onChange(items)
      setEnqueueSave(false)
    }
  }, [enqueueSave, state])

  const handleStart = (evt: React.MouseEvent) => {
    activeTool.forEach(tool => {
      const point: Point = {
        x: evt.pageX,
        y: evt.pageY,
      }

      switch (tool.itemType) {
        case ItemType.ELLIPSE:
        case ItemType.LINE:
        case ItemType.RECT:
          dispatch({
            type: ActionType.DRAW_START,
            payload: { type: tool.itemType, point, item: None<Item>() },
          })
          break

        case ItemType.SELECT_AREA:
          dispatch({
            type: ActionType.SELECT_START,
            payload: point,
          })
          break
      }
    })
  }

  const handleMove = (evt: React.MouseEvent) => {
    if (evt.buttons !== MOUSE_LEFT_BUTTON) {
      return
    }

    state.activity.type.forEach(activityType => {
      const point: Point = {
        x: evt.pageX,
        y: evt.pageY,
      }

      const proportional = evt.shiftKey

      switch (activityType) {
        case ActivityType.DRAW:
          dispatch({
            type: ActionType.DRAW_MOVE,
            payload: { point, proportional },
          })
          break

        case ActivityType.MOVE:
          dispatch({
            type: ActionType.MOVE_MOVE,
            payload: point,
          })
          break

        case ActivityType.SELECT:
          dispatch({
            type: ActionType.SELECT_MOVE,
            payload: { point, proportional },
          })
          break
      }
    })
  }

  const handleEnd = () => {
    state.activity.type.forEach(activityType => {
      switch (activityType) {
        case ActivityType.DRAW:
          dispatch({ type: ActionType.DRAW_END })
          setEnqueueSave(true)
          break

        case ActivityType.MOVE:
          dispatch({ type: ActionType.MOVE_END })
          setEnqueueSave(true)
          break

        case ActivityType.SELECT:
          dispatch({ type: ActionType.SELECT_END })
          break
      }
    })
  }

  const handleAddToSelection = (item: Item) => {
    dispatch({
      type: ActionType.SELECT_ITEM,
      payload: item,
    })
  }

  const handleRemoveFromSelection = (item: Item) => {
    dispatch({
      type: ActionType.DESELECT_ITEM,
      payload: item,
    })
  }

  const handleReplaceSelection = (item: Item) => {
    dispatch({
      type: ActionType.DESELECT_ALL_ITEMS,
    })

    dispatch({
      type: ActionType.SELECT_ITEM,
      payload: item,
    })
  }

  const handleMoveStart = (point: Point) => {
    dispatch({
      type: ActionType.MOVE_START,
      payload: point,
    })
  }

  const handleResizeStart = (item: Item, point: Point) => {
    dispatch({
      type: ActionType.DRAW_START,
      payload: { type: item.type, point, item: Some(item) }
    })
  }

  const renderItem = (item: Item) => {
    const activity = state.activity

    const drawing = activity.item.equals(Some(item))
    const resizable = R.keys(activity.selected).length <= 1
    const selected = item.id.map(itemId => activity.selected[itemId] === true).orSome(false)
    const selectable = activeTool.map(tool => tool.id).equals(Some('select'))
    const itemId = item.id.orSome('pending')

    let component: React.ComponentType<ShapeProps>

    switch (item.type) {
      case ItemType.ELLIPSE:
        component = Ellipse
        break

      case ItemType.LINE:
        component = Line
        break

      case ItemType.RECT:
        component = Rect
        break

      case ItemType.SELECT_AREA:
        component = SelectArea
        break
    }

    if (!component) {
      return None<React.FunctionComponentElement<ShapeProps>>()
    }

    return Some(React.createElement(component, {
      key: itemId,
      item,
      drawing,
      resizable,
      selectable,
      selected,
      onAddToSelection: () => handleAddToSelection(item),
      onRemoveFromSelection: () => handleRemoveFromSelection(item),
      onReplaceSelection: () => handleReplaceSelection(item),
      onMoveStart: handleMoveStart,
      onResizeStart: (point: Point) => handleResizeStart(item, point),
    }))
  }

  const items = R.map(
    item => renderItem(item).orNull(),
    R.filter<Item>(
      item => !state.activity.item.flatMap(item => item.id).equals(item.id),
      R.values(state.items)))

  return (
    <Container style={{ cursor }} onMouseDown={handleStart} onMouseMove={handleMove} onMouseUp={handleEnd}>
      {grid(state.gridSize)}
      {items}
      {state.activity.item.flatMap(renderItem).orNull()}
    </Container>
  )
}
