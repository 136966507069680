import { inject, injectable } from 'tsyringe'

import { ProjectStore } from '../stores/ProjectStore'
import { Item } from '../types/domain'

@injectable()
export class ProjectService {
  constructor(@inject('ProjectStore') private readonly projectStore: ProjectStore) {}

  public save(items: Item[]) {
    this.projectStore.set(items)
  }

  public load(): Promise<Item[]> {
    return this.projectStore.get()
  }
}
