import * as R from 'ramda'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { Item, Point } from '../../types/domain'

export interface Props {
  item: Item
  drawing: boolean
  resizable: boolean
  selectable: boolean
  selected: boolean
  showNonIntersectingResizeHandles?: boolean
  className?: string

  onAddToSelection(): void
  onRemoveFromSelection(): void
  onReplaceSelection(): void
  onMoveStart(anchor: Point): void
  onResizeStart(anchor: Point): void
}

const Container = styled.g``

const HoverTargetGroup = styled.g`
  fill: none;
  stroke: transparent;
  stroke-width: 2rem;
`
interface RenderGroupProps {
  hovered: boolean
  active: boolean
}

const RenderGroup = styled.g<RenderGroupProps>`
  fill: none;
  pointer-events: none;
  stroke: #fff;
  stroke-width: 0.2rem;
  transition:
    stroke-width 250ms ease-in,
    stroke 250ms linear;

  ${props => props.active && css`
    stroke: #ffcc70;
  `}

  ${props => props.hovered && css`
    stroke-width: 0.4rem;
  `}
`

const RESIZE_HANDLE_SIZE = 8

const shouldShowSmallResizeHandles = (item: Item) => {
  const { x1, x2, y1, y2 } = item.boundingBox
  const width = Math.abs(x2 - x1)
  const height = Math.abs(y2 - y1)
  return width < 20 && height < 20
}

const ResizeHandleGroup = styled.g``

const ResizeOutline = styled.rect`
  fill: none;
  pointer-events: none;
  stroke: #ffcc70;
  stroke-dasharray: 1, 1;
  transition: stroke 250ms linear;
  z-index: 2;
`

const ResizeHandle = styled.rect`
  fill: #ffcc70;
  z-index: 2;
`

const ShapeImpl: React.FC<Props> = props => {
  const {
    children,
    className,
    drawing,
    item,
    resizable,
    selectable,
    selected,
    showNonIntersectingResizeHandles,
    onAddToSelection,
    onMoveStart,
    onRemoveFromSelection,
    onReplaceSelection,
    onResizeStart,
  } = props

  const [hovered, setHovered] = useState(false)
  const active = drawing || selected
  const { x1, x2, y1, y2 } = item.boundingBox

  const handleEnter = () => {
    if (!drawing && selectable) {
      setHovered(true)
    }
  }

  const handleLeave = () => {
    if (selectable) {
      setHovered(false)
    }
  }

  const handleSelect = (evt: React.MouseEvent) => {
    evt.stopPropagation()

    const point: Point = {
      x: evt.pageX,
      y: evt.pageY,
    }

    if (evt.shiftKey) {
      if (selected) {
        onRemoveFromSelection()
      } else {
        onAddToSelection() 
        onMoveStart(point)
      }
    } else {
      if (!selected) {
        onReplaceSelection()
      }

      onMoveStart(point)
    }
  }

  const handleResizeStart = (point: Point) => (evt: React.MouseEvent) => {
    evt.stopPropagation()
    onResizeStart(point)
  }

  const resizeHandleSize = shouldShowSmallResizeHandles(item)
    ? RESIZE_HANDLE_SIZE / 2
    : RESIZE_HANDLE_SIZE

  const resizeHandleOffset = resizeHandleSize / 2
  const isInverted = x2 < x1 || y2 < y1

  return (
    <Container>
      <HoverTargetGroup onMouseEnter={handleEnter} onMouseLeave={handleLeave} onMouseDown={handleSelect}>
        {React.Children.only(children)}
      </HoverTargetGroup>

      <RenderGroup className={className} active={active} hovered={hovered}>
        {React.Children.only(children)}  
      </RenderGroup>

      {!drawing && selected && resizable && (
        <ResizeHandleGroup>
          {showNonIntersectingResizeHandles && (
            <ResizeOutline
              x={Math.min(x1, x2)}
              y={Math.min(y1, y2)}
              width={Math.abs(x2 - x1)}
              height={Math.abs(y2 - y1)}
            />
          )}

          <ResizeHandle
            x={x1 - resizeHandleOffset}
            y={y1 - resizeHandleOffset}
            width={resizeHandleSize}
            height={resizeHandleSize}
            onMouseDown={handleResizeStart({ x: x2, y: y2 })}
            style={{ cursor: isInverted ? 'nesw-resize' : 'nwse-resize' }}
          />

          {showNonIntersectingResizeHandles && (
            <ResizeHandle
              x={x2 - resizeHandleOffset}
              y={y1 - resizeHandleOffset}
              width={resizeHandleSize}
              height={resizeHandleSize}
              onMouseDown={handleResizeStart({ x: x1, y: y2 })}
              style={{ cursor: isInverted ? 'nwse-resize' : 'nesw-resize' }}
            />
          )}

          {showNonIntersectingResizeHandles && (
            <ResizeHandle
              x={x1 - resizeHandleOffset}
              y={y2 - resizeHandleOffset}
              width={resizeHandleSize}
              height={resizeHandleSize}
              onMouseDown={handleResizeStart({ x: x2, y: y1 })}
              style={{ cursor: isInverted ? 'nwse-resize' : 'nesw-resize' }}
            />
          )}

          <ResizeHandle
            x={x2 - resizeHandleOffset}
            y={y2 - resizeHandleOffset}
            width={resizeHandleSize}
            height={resizeHandleSize}
            onMouseDown={handleResizeStart({ x: x1, y: y1 })}
            style={{ cursor: isInverted ? 'nesw-resize' : 'nwse-resize' }}
          />
        </ResizeHandleGroup>
      )}
    </Container>
  )
}

ShapeImpl.defaultProps = {
  showNonIntersectingResizeHandles: true,
}

export const Shape = React.memo(ShapeImpl, (prevProps, nextProps) => {
  return R.equals(prevProps.item, nextProps.item)
    && prevProps.resizable === nextProps.resizable
    && prevProps.selected === nextProps.selected
    && prevProps.selectable === nextProps.selectable
    && prevProps.drawing === nextProps.drawing
})
